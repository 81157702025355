import React from "react";
import { Box, Typography } from "@mui/material";

function TextSection() {
  return (
    <section id="text">
      <Box sx={{ width: "100%", m: "2em auto 0", textAlign: "center" }}>
        <Typography variant="h3" sx={{ fontWeight: 700, m: "0 auto", maxWidth: '80%' }}>
        Discover the World of Free Gaming at Pokies-blase Game
        </Typography>
      </Box>
      <Box sx={{ width: "100%", m: "0 auto 2em", textAlign: "left" }}>
        <Typography
          component="p"
          sx={{ m: "0 auto", p: "1em 0 2em", fontSize: "18px", maxWidth: "80%" }}
        >
          Embark on an unforgettable gaming journey at Pokies-blase Game, the heart of free online gaming. Here, we bring your gaming fantasies to life, offering an extensive collection of free, high-quality online games. At Pokies-blase Game, our dedication to gaming fuels our mission to provide gamers from all walks of life with exceptional entertainment at no cost. Dive into a universe filled with captivating adventures, challenging puzzles, strategic gameplay, and so much more - all easily accessible.
          <Typography sx={{ fontSize: "32px", m: ".5em 0" }}>
          Why Pokies-blase Game Stands Out:
          </Typography>
          Expansive Game Selection: Our library at Pokies-blase Game boasts a wide range of genres. From high-octane action for thrill-seekers to complex puzzles for those who love a mental workout, tactical games for strategists, and relaxing simulations for leisure gamers, we have it all. Our aim is to entertain everyone, whether you're a casual gamer or a dedicated enthusiast.
          <br />
          <br />
          Completely Free and User-Friendly: We believe in making gaming available to everyone. That's why all our games are free, with no hidden costs or in-game purchases. We prioritize your gaming experience, ensuring it is enjoyable and cost-free.
          <br />
          <br />
          Play Instantly: Say goodbye to the hassle of downloads and installations. With Pokies-blase Game, you can start playing your chosen game directly in your browser - a smooth and uninterrupted gaming experience is guaranteed.
          <br />
          <br />
          Continuously Updated Library: Our team is always on the lookout for the newest and most popular games, updating our collection regularly. This means you'll always have fresh adventures to embark on and timeless classics to revisit.
          <br />
          <br />
          Engaging Community and Exciting Competitions: Pokies-blase Game is more than just a gaming platform; it's a community. Connect with fellow gamers, exchange strategies, and take part in exciting competitions and challenges. Gaming is about community and shared experiences, and Pokies-blase Game offers the perfect environment for that.
          <Typography sx={{ fontSize: "32px", m: ".5em 0" }}>
          Get in Touch
          </Typography>
          We're always eager to hear from you. If you have any inquiries, suggestions, or just want to chat, feel free to contact us at contact@pokies-blasegame.com. Your feedback is crucial in helping us improve your gaming experience. Reach out to us; we're here to make your time at Pokies-blase Game even more rewarding.
        </Typography>
      </Box>
    </section>
  );
}

export default TextSection;
