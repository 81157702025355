import React from "react";
import Box from "@mui/material/Box";

function Policy() {
  return (
    <section>
      <Box sx={{background: 'white', p: '1.5em 2em', borderRadius: '1em'}}>
        <h2>Privacy policy</h2>
        <p>
          This Privacy Policy ("Policy") describes how pokies-blasegame.com ("we,"
          "us," or "our") collects, uses, shares, and protects the personal
          information of visitors and users ("you" or "your") of the
          pokies-blasegame.com website and related services (collectively, the
          "Services").
          <br />
          <br />
          By using our Services, you agree to the practices described in this
          Policy. If you do not agree to this Policy, please do not use our
          Services.
        </p>

        <h4>1. Information We Collect</h4>
        <p>
          We may collect the following types of information:
          <br />
          <br />
          1.1 Personal Information: We may collect personally identifiable
          information such as your name, email address, postal address, and
          other information you voluntarily provide when you interact with our
          Services.
          <br />
          <br />
          1.2 Usage Information: We may collect non-personal information about
          your interactions with our Services, including but not limited to your
          IP address, browser type, operating system, and pages visited. We may
          also use cookies and similar technologies to collect this information.
        </p>

        <h4>2. How We Use Your Information</h4>
        <p>
          We use your information for the following purposes:
          <br />
          <br />
          2.1 Providing and Improving Services: We use your information to
          provide, maintain, and improve our Services, including responding to
          your inquiries and requests.
          <br />
          <br />
          2.2 Analytics: We use analytics tools to analyze user trends and
          preferences, which helps us improve our Services.
        </p>

        <h4>3. Sharing Your Information</h4>
        <p>
          We do not sell, rent, or trade your personal information to third
          parties. However, we may share your information in the following
          situations:
          <br />
          <br />
          3.1 Legal Requirements: We may disclose your information if required
          by law, regulation, legal process, or government request.
          <br />
          <br />
          3.2 Protection of Rights: We may disclose your information when we
          believe it is necessary to protect our rights, privacy, safety, or
          property, or to respond to an emergency that threatens the safety of
          any person.
        </p>

        <h4>4. Your Choices</h4>
        <p>
          You have the following choices regarding your personal information:
          <br />
          <br />
          4.1 Access and Correction: You may access and correct your personal
          information by contacting us at admin@pokies-blasegame.com.
          <br />
          <br />
          4.2 Opt-Out: You may opt-out of receiving promotional emails from us
          by following the instructions in those emails.
        </p>

        <h4>5. Security</h4>
        <p>
          We use reasonable administrative, technical, and physical measures to
          protect your information. However, no method of transmission over the
          internet or electronic storage is entirely secure, and we cannot
          guarantee absolute security.
        </p>

        <h4>6. Children's Privacy</h4>
        <p>
          Our Services are not intended for individuals under the age of 13. If
          you believe that we have inadvertently collected information from a
          child under 13, please contact us, and we will promptly delete such
          information.
        </p>

        <h4>7. Changes to this Privacy Policy</h4>
        <p>
          We may update this Privacy Policy from time to time. Please review
          this Policy periodically to stay informed about our data practices.
        </p>

        <h4>8. Contact Us</h4>
        <p>
          If you have any questions, concerns, or requests regarding this
          Privacy Policy or our data practices, please contact us at
          admin@pokies-blasegame.com.
        </p>
      </Box>
    </section>
  );
}

export default Policy;
