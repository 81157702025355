import React from 'react'

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";

function FreeOnlineGamesLabel() {
  return (
   
    <Snackbar open={true} autoHideDuration={6000}>
    <Alert
      severity="info"
      icon={false}
      sx={{
        background: "rgba(0, 0, 0, 0.5)",
        fontWeight: 700,
        p: "0 0.5em",
        color: 'white'
      }}
    >
    <Typography component="span" sx={{ m: "0 0.5em", color: "#fff" }}>
        Free Online Games
    </Typography>
    </Alert>
  </Snackbar>
  )
}

export default FreeOnlineGamesLabel