import React from "react";
import DocumentMeta from "react-document-meta";
import GamesBlock from "../components/contentSections/GamesBlock";
import TextSection from "../components/contentSections/TextSection";
import Hero from "../components/contentSections/Hero";

const meta = {
  title: "Home | Pokies-blase Game | Free Online Games",
  description: "We're passionate about gaming, and we've made it our mission to ensure that gamers of all ages and backgrounds have access to top-notch entertainment without any cost barriers. ",
  canonical: "https://pokies-blasegame.com/",
  meta: {
    charset: "utf-8",
  },
};

function Main() {
  return (
    <DocumentMeta {...meta}>
      <Hero />
      <GamesBlock />
      <TextSection />
    </DocumentMeta>
  );
}

export default Main;
