import React from "react";
import { Box, Typography } from "@mui/material";

function About() {
  return (
    <section id="about">
      <Box sx={{ width: "100%", m: "1em auto", textAlign: "center" }}>
        <Typography variant="h1" sx={{ m: "0 auto", fontWeight: 700 }}>
          About us
        </Typography>
      </Box>
      <Box sx={{ width: "100%", m: "1em auto", textAlign: "left" }}>
        <Typography
          component="p"
          sx={{
            m: "0 auto",
            p: "2em 0",
            fontSize: "18px",
            width: "80%",
          }}
        >
          Pokies-blase Game was born from a shared passion for gaming and a vision to
          create an inclusive platform where everyone could revel in the joy of
          gaming without any barriers. Our journey began as a modest project,
          where we handpicked a handful of games that we adored and believed
          others would too. As our platform flourished, so did our dedication to
          building a haven for gamers from all walks of life.
          <Typography sx={{ fontSize: "32px", m: ".5em 0" }}>
            Our Story
          </Typography>
          Our story is one of unwavering dedication, fueled by a deep love for
          gaming and a desire to share that passion with the world. Pokies-blase Game
          started as a small initiative, and over time, it blossomed into a
          thriving community of gamers united by their love for interactive
          experiences. We're proud of the journey we've undertaken and the
          gaming sanctuary we've constructed.
          <Typography sx={{ fontSize: "32px", m: ".5em 0" }}>
            Our Values
          </Typography>
          Inclusivity: We firmly believe that gaming should be an inclusive
          space for all, irrespective of age, gender, or background. Pokies-blase Game is committed to providing a safe and welcoming environment for
          every gamer.
          <br />
          <br />
          Quality: Our commitment to excellence is unwavering. Each game
          featured on our platform undergoes rigorous testing to ensure it meets
          our stringent criteria for quality and enjoyment.
          <br />
          <br />
          Community: Gaming is not just about playing; it's about building
          connections with others who share your passion. Pokies-blase Game
          encourages collaboration, friendly competition, and the formation of
          lasting friendships within our community.
          <br />
          <br />
          Accessibility: Our platform is designed to be user-friendly and
          accessible to all. Whether you're a seasoned gamer or a newcomer,
          you'll find it easy to navigate and enjoy our games.
          <Typography sx={{ fontSize: "32px", m: ".5em 0" }}>
            Meet the Team
          </Typography>
          The success of Pokies-blase Game owes itself to the dedicated individuals
          working tirelessly behind the scenes. Our team comprises gamers,
          developers, designers, and community managers who share a common goal:
          to make your gaming experience truly exceptional. As gamers ourselves,
          we understand your expectations and continuously strive to surpass
          them.
          <Typography sx={{ fontSize: "32px", m: ".5em 0" }}>
            Join Us on This Gaming Adventure!
          </Typography>
          Pokies-blase Game cordially invites you to embark on an exhilarating gaming
          adventure with us. Explore our extensive collection of free online
          games, connect with fellow gamers, and become a part of our rapidly
          growing community. We're here to provide you with the finest gaming
          experiences, and we eagerly anticipate your presence in our virtual
          world of fun and excitement.
          <br />
          <br />
          Thank you for choosing Pokies-blase Game as your gaming destination. Let's
          play together and create unforgettable gaming memories!
        </Typography>
      </Box>
    </section>
  );
}

export default About;
