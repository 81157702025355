import { Box, Typography } from "@mui/material";
import React from "react";

function ContactText() {
  return (
    <section id="contact">
    <Box
      sx={{
        m: "2em auto 5em",
        textAlign: "center",
      }}
    >
      <Typography variant="h3">Contact Us</Typography>
      
      <Typography
          component="p"
          sx={{ m: "0 auto", pt: "2em", fontSize: "18px", maxWidth: "1000px", textAlign: 'left' }}
        >
          Your input matters to us. Don't hesitate to reach out us by email at contact@pokies-blasegame.com. We value your feedback, suggestions, and questions. Your input helps us fine-tune our platform and deliver the best gaming experience possible. Whether you want to share your thoughts or simply say hello, we're here to listen and assist you. Connect with us and be a part of the Pokies-blase Game community!
      </Typography>
    </Box>

    </section>
  )
}

export default ContactText