import React from "react";
import DocumentMeta from "react-document-meta";
import { Box, Typography } from "@mui/material";
import FeaturedGames from "../components/contentSections/FeaturedGames";

const meta = {
  title: "All Games | Pokies-blase Game  |   Free Online Games",
  description: "Welcome to Pokies-blase Game, the epicenter of free online gaming where your gaming dreams come to life! ",
};

function Games() {
  return (
    <DocumentMeta {...meta}>
      <Box sx={{mt: '2em'}}>
        <Typography variant="h1" sx={{mb: '.5em', textAlign: 'center', color: '#fff'}}>
          All games
        </Typography>
      <FeaturedGames />
      </Box>
    </DocumentMeta>
  );
}

export default Games;
