import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Main from "./pages/Main";
import About from "./pages/About";
import Games from "./pages/Games";
import Disclaimer from "./pages/Disclaimer";
import Policy from "./pages/Policy";
import Box from "@mui/material/Box";
import CookiePopup from "./components/labels/CookiePopup";
import AgeLabel from "./components/labels/AgeNoMoney";
import FreeOnlineGamesLabel from "./components/labels/FreeOnlineGames";
import ScrollToTop from "./ScrollToTop";

import "./theme/style.css";

function App() {
  return (
    <BrowserRouter>
      <Header />
    <ScrollToTop />

      <Box component="main" sx={{ p: 0 }}>
        <Routes>
          <Route path="/">
            <Route index element={<Main />} />
            <Route path="games" element={<Games />} />
            <Route path="about" element={<About />} />
            <Route path="disclaimer" element={<Disclaimer />} />
            <Route path="privacy-policy" element={<Policy />} />
          </Route>
        </Routes>
      </Box>
      <AgeLabel />
      <FreeOnlineGamesLabel />
      <CookiePopup />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
