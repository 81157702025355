import React from "react";
import { Box } from "@mui/material";
import logo from "../../theme/img/logo.png";

function Hero() {
  return (
    <section className="hero">
      <Box
        sx={{
          p: '1em 3em 5em',
          borderRadius: "1em",
          display: "flex",
          flexDirection: 'column',
          justifyContent: "center",
          alignItems: "center",
          textAlign: 'left',
          color: '#111'
        }}
        variant="outlined"
      >
        <Box sx={{m: '3em auto 1em'}}>
          <img
            src={logo}
            width="350px"
            alt="logo"
          />
        </Box>
      </Box>
    </section>
  );
}

export default Hero;
