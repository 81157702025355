import React from "react";
import DocumentMeta from "react-document-meta";
import AboutSection from '../components/contentSections/About';
import ContactText from "../components/contentSections/ContactText";

const meta = {
  title: "About | Pokies-blase Game  |   Free Online Games",
  description: "Our journey began as a modest project, where we handpicked a handful of games that we adored and believed others would too. As our platform flourished, so did our dedication to building a haven for gamers from all walks of life.",
};

function About() {
  return (
    <DocumentMeta {...meta}>
      <AboutSection />
      <ContactText />
    </DocumentMeta>
  );
}

export default About;
