import React, { useEffect, useMemo, useState } from "react";
import ImageCard from "../Card";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import games from "../../data/games";

function FeaturedGames({ filter }) {
  const [gamesList, setGamesList] = useState([]);

  useEffect(() => {
    if (typeof filter === "number") {
      const newList = games.slice(0, filter);
      setGamesList(newList);
    }
    if (!filter) {
      if(games.length > 40) 
      setGamesList(games.sort(() => 0.5 - Math.random()))
    }
     
  }, [filter]);

  useEffect(() => {
      if(gamesList.length <= 80) {
        const prevState = gamesList;
        setGamesList(prevState.concat(games))

      }
     
  }, [gamesList.length]);

  return (
    <>
      <Box
        sx={{
          width: "90%",
          m: "0 auto 4em",
          p: "0 0",
          textAlign: "center",
          boxSizing: "border-box",
        }}
      >
        {gamesList?.map((item, index) => (
            <Box sx={{display: 'inline-block'}}>
            <ImageCard
                key={item.title + 'featured' + index}
                img={item.img}
                title={item.title}
                src={item.url}
                width="200px"
                height="200px"
            />

            </Box>
        ))}
      </Box>
    </>
  );
}

export default FeaturedGames;